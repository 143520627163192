import React from "react"
import Layout from "../components/layout"
import Section from "../components/shared/Section"
import SEO from "../components/shared/SEO"
import StyledLink from "../components/shared/StyledLink"

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Section title="Oops!" subtitle="We couldn't find this page">
        <StyledLink isPrimary to="/">
          Back to Home
        </StyledLink>
      </Section>
    </Layout>
  )
}

export default NotFoundPage
